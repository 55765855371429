/**
 * Created by Mikhail Menshenin on 13.12.2024
 */

var Persist = function () {
    this.persistNodes = {};
};

Persist.prototype.sendNode = function (node, name) {
    var key = name || node.__instanceId;
    this.persistNodes[key] = node;
    var movingNode = cleverapps.scenes.getMovingNode(node);
    node.replaceParentSamePlace(movingNode, { keepScale: true });
};

Persist.prototype.acceptNode = function (name, recipient) {
    var key = name || Object.keys(this.persistNodes)[0];
    var node = this.persistNodes[key];
    if (!node) {
        return;
    }

    delete this.persistNodes[key];
    if (recipient) {
        node.replaceParentSamePlace(recipient, { keepScale: true });
    }
    return node;
};
