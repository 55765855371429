/**
 * Created by iamso on 31.08.2021
 */

var SingleProductOfferWindow = CleverappsWindow.extend({
    ctor: function (offer) {
        this.offer = offer;
        this.reward = this.offer.logic.reward;
        this.bundle = bundles[this.offer.bundle];
        this._super(offer);
    },

    onWindowLoaded: function () {
        if (["tropical", "heroes"].indexOf(cleverapps.config.ui) === -1 && [Offers.TYPES.LIVESFEAST, Offers.TYPES.SOFTFEAST].indexOf(this.offer.type) !== -1) {
            this.withCustomTitle = true;
        }
        if ([Offers.TYPES.BLACKFRIDAY].indexOf(this.offer.type) !== -1) {
            this.withCustomTitle = true;
        }

        this.offer.offerWindowShown = true;

        this._super({
            name: "SingleProductOfferWindow",
            content: this.createContent(),
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            title: !this.withCustomTitle && "SingleProductOfferWindow." + this.offer.name + ".title",
            openSound: this.bundle.urls.offer_showup_effect && this.bundle.urls.offer_showup_effect,
            customTitle: this.withCustomTitle
        });

        var hide = ["MenuBarCoinsItem", "MenuBarLivesItem"];
        var show = ["MenuBarGoldItem"];
        if (this.reward.energy) {
            show.push(hide.pop());
        }
        cleverapps.focusManager.showControlsWhileFocused(show);
        cleverapps.focusManager.hideControlsWhileFocused(hide);

        this.offer.logic.onBought = this.createListener(this.close.bind(this));

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);
    },

    updateButton: function () {
        if (connector.payments.isConnected() || this.offer.logic.getProduct() instanceof VirtualProduct) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    getPerson: function () {
        var person = cleverapps.styles.SingleProductOfferWindow[this.offer.type].person;
        if (person) {
            return cleverapps.persons.choose(person.role) ? person : {
                role: "worker",
                emotion: "happy"
            };
        }
    },

    createContent: function () {
        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type];

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.width, styles.height);

        this.createOffer();
        if (this.withCustomTitle) {
            this.createTitle();
        }
        this.createBuyButton();
        this.createFootnote();
        this.createBadges();

        return content;
    },

    createTitle: function () {
        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type];

        this.title = cleverapps.UI.generateOnlyText("SingleProductOfferWindow." + this.offer.name + ".title", styles.title.font);
        this.title.setPositionRound(styles.title);
        this.title.fitTo(styles.title.width);
        this.content.addChild(this.title, 1);

        this.title.setOpacity(0);
        this.title.runAction(new cc.Spawn(
            new cc.FadeIn(0.7),
            new cc.Sequence(
                new cc.ScaleTo(0.23, 1.1),
                new cc.ScaleTo(0.21, 1)
            ),
            new cc.Sequence(
                new cc.MoveBy(0.21, 0, -styles.title.offsetY),
                new cc.MoveBy(0.23, 0, styles.title.offsetY)
            )
        ));
    },

    createOffer: function () {
        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type];

        var animation = this.offerAnimation = new cleverapps.Spine(this.bundle.jsons.offer_json);
        if (this.offer.type === Offers.TYPES.KRAKENFEAST && cleverapps.config.name === "mergecraft") {
            animation.setSkin("0");
        } else if (this.offer.type === Offers.TYPES.BLACKFRIDAY) {
            animation.setSkin("expedition");
        }

        animation.setPositionRound(styles.offer);
        this.content.addChild(animation);
        animation.setLocalZOrder(0);
        this.chooseAnimation();

        for (var reward in this.reward) {
            if (reward === "unit" && styles.rewards[reward]) {
                this.reward[reward].forEach(function (unit) {
                    if (styles.rewards[reward][unit.code]) {
                        this.createReward(reward, unit);
                    }
                }, this);
            } else if (reward === "boosters") {
                for (var boosterType in this.reward[reward]) {
                    this.createReward(reward + "_" + boosterType, this.reward[reward][boosterType]);
                }
            } else {
                this.createReward(reward, this.reward[reward]);
            }
        }
    },

    createReward: function (type, value) {
        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type];
        var rewardsStyles = styles.rewards[type];

        if (!rewardsStyles) {
            return;
        }

        if (type === "worker") {
            value = value.title;
        }

        if (type === "unit") {
            rewardsStyles = rewardsStyles[value.code];
            value = value.pointsValue || value.amount || 1;
        }

        var delay = rewardsStyles.delay || styles.rewards.delay;
        var msg = typeof value === "number" ? "x" + value : value;
        if (Messages.has("SingleProductOfferWindow." + this.offer.name + ".reward." + type)) {
            msg = Messages.get("SingleProductOfferWindow." + this.offer.name + ".reward." + type);
        }

        var text;
        if (cleverapps.UI.ImageFont.IsApplicable(styles.rewards.font, msg)) {
            text = cleverapps.UI.generateImageText(msg, styles.rewards.font);
        } else {
            text = cleverapps.UI.generateOnlyText(msg, styles.rewards.font);
        }
        this.content.addChild(text);
        text.fitTo(rewardsStyles.width);
        text.setRotation(rewardsStyles.rotation);
        text.setPositionRound(rewardsStyles);

        if (delay) {
            text.setOpacity(styles.rewards.spawn ? 255 : 0);
            text.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.FadeIn(0.5)
            ));
        }
    },

    createBuyButton: function () {
        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type].button;

        this.button = new cleverapps.UI.Button({
            text: this.offer.logic.getPriceText(),
            width: styles.width,
            height: styles.height,
            onClicked: this.offer.logic.buy.bind(this.offer.logic)
        });

        this.button.performRecursive(function (node) {
            node.setCascadeOpacityEnabled(true);
        });

        this.button.setOpacity(0);
        this.button.setScale(0.3);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.Spawn(
                new cc.FadeIn(0.3),
                new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut())
            )
        ));

        if (styles.label) {
            var label = new cc.Sprite(this.bundle.frames.btn_label);
            var msgs = Messages.get("SingleProductOfferWindow." + this.offer.name + ".btnLabel").split("\n");
            msgs = msgs.map(function (line, ind) {
                var msg = cleverapps.UI.generateOnlyText(line, cleverapps.styles.FONTS.TILE_SHOP_BADGE_BIG);
                var height = ind === 0 ? styles.label.text.height * 0.55 : styles.label.text.height * 0.45;
                msg.fitTo(styles.label.text.width, height);
                return msg;
            });

            var text = new cleverapps.Layout(msgs, {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.label.text.margin
            });
            text.setPositionRound(styles.label.text);
            text.setRotation(styles.label.text.rotation);
            label.addChild(text);

            this.button.addChild(label);
            label.setPositionRound(styles.label);
        }

        this.content.addChild(this.button);
        this.button.setPositionRound(styles);
        this.button.setLocalZOrder(1);

        this.updateButton();
    },

    createFootnote: function () {
        var txtCode = "SingleProductOfferWindow." + this.offer.name + ".footnote";
        if (!Messages.has(txtCode)) {
            return;
        }

        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type];

        var note = cleverapps.UI.generateOnlyText(txtCode, cleverapps.styles.FONTS.WHITE_TEXT);
        note.setPositionRound(styles.footNote);
        note.setDimensions(styles.footNote.width, 0);
        note.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        note.fitTo(undefined, styles.footNote.height);
        this.content.addChild(note);

        note.setOpacity(0);
        note.runAction(new cc.Sequence(
            new cc.DelayTime(styles.footNote.delay),
            new cc.FadeIn(0.7)
        ));
    },

    createBadges: function () {
        var styles = cleverapps.styles.SingleProductOfferWindow[this.offer.type];

        if (!styles.badges) {
            return;
        }

        for (var i = 0; i < styles.badges.length; i++) {
            var badgeStyle = styles.badges[i];
            var badge = new TileBadge({
                text: "SingleProductOfferWindow." + this.offer.name + ".badge" + (i + 1),
                rotation: badgeStyle.rotation,
                largeFont: badgeStyle.largeFont
            });
            badge.setPositionRound(badgeStyle);
            this.content.addChild(badge);
        }
    },

    chooseAnimation: function () {
        var animationName = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "vertical" : "";
        var openAnimationName = this.offerAnimation.hasAnimation("open_" + animationName)
            ? "open_" + animationName : "open";
        var idleAnimationName = this.offerAnimation.hasAnimation("idle_" + animationName)
            ? "idle_" + animationName : "idle";
        this.offerAnimation.setAnimationAndIdleAfter(openAnimationName, idleAnimationName);
    },

    listBundles: function (offer) {
        return [offer.bundle];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SINGLE_PRODUCT_OFFER_ENERGY_TITLE: {
        size: 75,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.BROWN_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_DESCRIPTION: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_SOFT_TITLE: {
        size: 115,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.BROWN_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_REWARD: {
        name: "big_digits",
        size: 80,
        color: cleverapps.styles.COLORS.COLOR_BROWN_2,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_REWARD2: {
        name: "big_digits",
        size: 80,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_REWARD3: {
        name: "big_digits",
        size: 65,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_BF_TITLE: {
        size: 115,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.BLUE_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_BF_REWARD: {
        name: "big_digits",
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.BLUE_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_SALE_REWARD: {
        name: "default",
        size: 72,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SINGLE_PRODUCT_OFFER_CLPASS_REWARD: {
        name: "default",
        size: 50,
        color: cleverapps.styles.COLORS.WHITE
    },

    SINGLE_PRODUCT_OFFER_BADGE: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    LARGE_WHITE_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.SingleProductOfferWindowTemplate = {
    width: 800,
    height: 840,

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 570 },
        width: 480,
        offsetY: 23,
        font: cleverapps.styles.FONTS.WINDOW_TITLE_TEXT,

        background: {
            x: { align: "center", dx: 10 },
            y: { align: "top", dy: 120 },
            scale: 1.3
        }
    },

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD
    },

    button: {
        width: 300,
        height: 125,
        x: { align: "center", dx: -5 },
        y: { align: "bottom", dy: -50 }
    },

    footNote: {
        delay: 2.5,
        width: 920,
        height: 85,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -170 }
    }
};

cleverapps.styles.SingleProductOfferWindow = {};

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.LIVESFEAST] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1400,

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 325 },
        width: 600,
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_ENERGY_TITLE,
        background: undefined
    },

    person: {
        role: "seller",
        emotion: "idle"
    },

    rewards: {
        energy: {
            x: { align: "center", dx: -535 },
            y: { align: "center", dy: -195 },
            width: 170,
            rotation: -5
        },

        worker: {
            x: { align: "center", dx: 535 },
            y: { align: "center", dy: -195 },
            width: 170,
            rotation: 5
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.SOFTFEAST] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1400,

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 325 },
        width: 600,
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_SOFT_TITLE,
        background: undefined
    },

    person: {
        role: "seller",
        emotion: "idle"
    },

    rewards: {
        energy: {
            x: { align: "center", dx: -535 },
            y: { align: "center", dy: -195 },
            width: 170,
            rotation: -5
        },

        soft: {
            x: { align: "center", dx: 535 },
            y: { align: "center", dy: -195 },
            width: 170,
            rotation: 5
        }
    },

    button: {
        x: { align: "center", dx: -5 },
        y: { align: "bottom", dy: -95 }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.KRAKENFEAST] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    height: 740,

    button: {
        y: { align: "bottom", dy: -25 }
    },

    footNote: {
        y: { align: "bottom", dy: -140 }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.BUILDPASS] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    badges: [
        {
            x: { align: "left", dx: 66 },
            y: { align: "top", dy: -11 },
            width: 125,
            rotation: 10
        }
    ]

}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.SALEPASS] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 210 }
    },
    badges: [
        {
            x: { align: "right", dx: -22 },
            y: { align: "top", dy: -58 },
            width: 125,
            rotation: 10
        }
    ],

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            bppointscrystal: {
                x: { align: "center", dx: -284 },
                y: { align: "center", dy: -262 },
                width: 250,
                font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_SALE_REWARD,
                rotation: 0
            }
        },

        soft: {
            x: { align: "center", dx: 273 },
            y: { align: "center", dy: -262 },
            width: 250,
            font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_SALE_REWARD,
            rotation: 0
        }
    },

    person: {
        role: "hunter",
        emotion: "happy"
    },

    button: {
        x: { align: "center", dx: -9 },
        y: { align: "bottom", dy: -60 }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.EXPEDITION] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -40 }
    },

    badges: [
        {
            x: { align: "center", dx: 613 },
            y: { align: "center", dy: -250 },
            width: 125
        }
    ],

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            dragonpack: {
                delay: 0.8,
                x: { align: "center", dx: -440 },
                y: { align: "center", dy: -370 },
                width: 240,
                rotation: 4
            }
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 440 },
            y: { align: "center", dy: -350 },
            width: 240,
            rotation: -2
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.DRAGONIA2] = cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.DRAGONIA3] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -40 }
    },

    badges: [
        {
            x: { align: "center", dx: 613 },
            y: { align: "center", dy: -250 },
            width: 125
        }
    ],

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            dr2dragonpack: {
                delay: 0.8,
                x: { align: "center", dx: -440 },
                y: { align: "center", dy: -370 },
                width: 240,
                rotation: 4
            }
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 440 },
            y: { align: "center", dy: -350 },
            width: 240,
            rotation: -2
        }
    }
}, true);

// eslint-disable-next-line max-len
cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.UNDERSEA] = cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.UNDERSEA2] = cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.UNDERSEA3] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1600,

    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -30 }
    },

    badges: false,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 100 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            underseapack: {
                delay: 0.8,
                x: { align: "center", dx: -625 },
                y: { align: "center", dy: -315 },
                width: 240,
                rotation: 0
            },
            sea2pack: {
                delay: 0.8,
                x: { align: "center", dx: -625 },
                y: { align: "center", dy: -315 },
                width: 240,
                rotation: 0
            }
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 673 },
            y: { align: "center", dy: -315 },
            width: 240,
            rotation: 0
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.SNAILFEAST] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1600,

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: -50 }
    },

    badges: false,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 5 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            seasnailhouse: {
                delay: 0.8,
                x: { align: "center" },
                y: { align: "center", dy: -325 },
                width: 240,
                rotation: 0
            },
            drsnailhouse: {
                delay: 0.8,
                x: { align: "center" },
                y: { align: "center", dy: -325 },
                width: 240,
                rotation: 0
            }
        }
    },

    footNote: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -48 },
        height: 90
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.BLACKFRIDAY] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1650,

    title: {
        x: { align: "center", dx: 50 },
        y: { align: "center", dy: 385 },
        width: 480,
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_BF_TITLE,
        background: undefined
    },

    offer: {
        y: { align: "center", dy: 2 }
    },

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: 40 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_BF_REWARD,
        unit: {
            dr2dragonpack: {
                x: { align: "center" },
                y: { align: "center", dy: 20 },
                width: 180,
                rotation: 0
            }
        },
        hard: {
            x: { align: "center", dx: 190 },
            y: { align: "center", dy: -190 },
            width: 240,
            rotation: 0
        },
        energy: {
            x: { align: "center", dx: -190 },
            y: { align: "center", dy: -190 },
            width: 240,
            rotation: 0
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.HALLOWEEN] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1650,

    offer: {
        y: { align: "center", dy: 2 }
    },

    button: {
        x: { align: "center", dx: -197 },
        y: { align: "bottom", dy: -20 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            dr2dragonpack: {
                delay: 0.8,
                x: { align: "center", dx: -604 },
                y: { align: "center", dy: -298 },
                width: 240,
                rotation: 0
            }
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 608 },
            y: { align: "center", dy: -298 },
            width: 240,
            rotation: 0
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.RAPUNZEL] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1650,

    offer: {
        y: { align: "center", dy: 25 }
    },

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: -50 },

        height: 115
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        energy: {
            delay: 0.8,
            x: { align: "center", dx: -477 },
            y: { align: "center", dy: -365 },
            width: 240,
            rotation: 0
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 477 },
            y: { align: "center", dy: -365 },
            width: 240,
            rotation: 0
        }
    }
}, true);
cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.RAPUNZEL3] = cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.RAPUNZEL2] = cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.RAPUNZEL];

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.XMAS] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1650,

    offer: {
        y: { align: "center", dy: -70 }
    },

    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -40 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            dr2dragonpack: {
                x: { align: "center", dx: -465 },
                y: { align: "center", dy: -325 },
                width: 240,
                rotation: 0
            }
        },
        hard: {
            x: { align: "center", dx: 460 },
            y: { align: "center", dy: -325 },
            width: 240,
            rotation: 0
        },
        energy: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -325 },
            width: 240,
            rotation: 0
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.EASTER] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1650,

    offer: {
        y: { align: "center", dy: 25 }
    },

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: -50 },

        height: 115
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        energy: {
            delay: 0.8,
            x: { align: "center", dx: -427 },
            y: { align: "center", dy: -375 },
            width: 180,
            rotation: 0
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 427 },
            y: { align: "center", dy: -375 },
            width: 180,
            rotation: 0
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.CHINA] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -40 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD2,
        unit: {
            dr2dragonpack: {
                delay: 0.8,
                x: { align: "center", dx: -545 },
                y: { align: "center", dy: -392 },
                width: 240,
                rotation: 4
            }
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 580 },
            y: { align: "center", dy: -377 },
            width: 240,
            rotation: -2
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.SPRING] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -40 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD3,
        unit: {
            dr2dragonpack: {
                delay: 0.8,
                x: { align: "center", dx: -495 },
                y: { align: "center", dy: -400 },
                width: 200
            }
        },
        hard: {
            delay: 0.8,
            x: { align: "center", dx: 470 },
            y: { align: "center", dy: -400 },
            width: 190
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.CLPASS] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: 1400,

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 325 },
        width: 600,
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_ENERGY_TITLE,
        background: undefined
    },

    person: {
        role: "seller",
        emotion: "idle"
    },

    button: {
        width: 320,
        y: { align: "bottom", dy: -80 },
        label: {
            x: { align: "right", dx: 134 },
            y: { align: "top", dy: 32 },
            text: {
                x: { align: "center", dx: 18 },
                y: { align: "center", dy: 5 },
                margin: -10,
                width: 180,
                height: 110,
                rotation: 34
            }
        }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_CLPASS_REWARD,
        hard: {
            x: { align: "center", dx: -573 },
            y: { align: "center", dy: -220 }
        },
        soft: {
            x: { align: "center", dx: 195 },
            y: { align: "center", dy: -340 }
        },
        unit: {
            crystal: {
                x: { align: "center", dx: 560 },
                y: { align: "center", dy: -240 }
            },
            clsack: {
                x: { align: "center", dx: -213 },
                y: { align: "center", dy: -340 }
            }
        }
    }
}, true);

cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.PINBALL_PACK] = cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindowTemplate, {
    width: [
        800,
        1200,
        1400
    ],

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 325 },
        width: 600,
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_ENERGY_TITLE,
        background: undefined
    },

    person: undefined,
    button: {
        width: 320,
        y: { align: "bottom", dy: -80 }
    },

    rewards: {
        delay: 0.45,
        font: cleverapps.styles.FONTS.LARGE_WHITE_TEXT,
        pinballChips: [
            {
                x: { align: "center", dx: 100 },
                y: { align: "center", dy: 10 }
            },
            {
                x: { align: "center", dx: 368 },
                y: { align: "center", dy: 40 }
            },
            {
                x: { align: "center", dx: 368 },
                y: { align: "center", dy: 40 }
            }
        ],
        boosters_before_medium: [
            {
                x: { align: "center", dx: -110 },
                y: { align: "center", dy: -235 }
            },
            {
                x: { align: "center", dx: 160 },
                y: { align: "center", dy: -190 }
            },
            {
                x: { align: "center", dx: 160 },
                y: { align: "center", dy: -190 }
            }
        ],
        boosters_before_expensive: [
            {
                x: { align: "center", dx: 125 },
                y: { align: "center", dy: -250 }
            },
            {
                x: { align: "center", dx: 400 },
                y: { align: "center", dy: -210 }
            },
            {
                x: { align: "center", dx: 400 },
                y: { align: "center", dy: -210 }
            }
        ]
    }
}, true);