/**
 * Created by slava on 4/5/17.
 */

cleverapps.SocialManager = {
    login: function (callback, options) {
        var waitWindow, onceWaitWindowClosed;

        if (connector.platform.oneOf(connector.MICROSOFT)) {
            cleverapps.focusManager.display({
                stack: true,
                focus: "WaitLogin",
                action: function (f) {
                    waitWindow = new WaitWindow();
                    onceWaitWindowClosed = f;
                }
            });
        }

        connector.social.loginCallback(function (success) {
            if (waitWindow) {
                waitWindow.close();
                onceWaitWindowClosed();
            }

            callback(success);
        }, options);
    },

    enforceLogin: function (callback, options) {
        options = options || {};

        if (cleverapps.flags.nologin) {
            callback(cleverapps.CODE_SUCCEED);
            return;
        }

        if (!connector.social.isConnected()) {
            cleverapps.notification.create("SocialManager.Initialization");
            callback(cleverapps.CODE_FAILED);
            return;
        }

        if (!connector.social.isLoggedIn({ strict: options.strict })) {
            if (options.withWindow || connector.social.needWindowForLogin) {
                new SignInWindow({
                    onSuccess: function () {
                        callback(cleverapps.CODE_SUCCEED);
                    },
                    onFailure: function () {
                        callback(cleverapps.CODE_FAILED);
                    },
                    errorMessage: options.errorMessage
                });
            } else {
                cleverapps.SocialManager.login(function (success) {
                    if (success) {
                        callback(cleverapps.CODE_SUCCEED);
                    } else {
                        callback(cleverapps.CODE_FAILED);
                    }
                });
            }
        } else {
            callback(cleverapps.CODE_SUCCEED);
        }
    },

    onFailedLogin: function () {
        cleverapps.notification.create("SocialManager.FailedLogging");
    },

    onLogin: function (params) {
        var newId = params.newId;
        var oldId = params.oldId;

        // tmp id --> social id
        if (PlatformLogic.isTmpId(oldId) || PlatformLogic.isDeviceId(oldId)) {
            cleverapps.RestClient.post("/users/off/" + oldId, { newId: newId }, function (response) {
                console.log("Success marked off: " + oldId + ", newId: " + newId + ", response " + JSON.stringify(response));
            }, function () {
                console.log("Failed marking off: " + oldId + ", newId: " + newId);
            });
            levels.user.setLoggedIn(Date.now());

            cleverapps.synchronizer.reset();

            cleverapps.info.onChangeUserId();

            if (cleverapps.userClan) {
                cleverapps.userClan.changeUserId(oldId, newId);
                cleverapps.userClan.load();
            }

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LOGIN, {
                method: connector.info.source
            });
        // social id --> social id
        } else if (oldId !== newId) {
            console.log("second login! old - " + oldId + ", new - " + newId);

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.SECOND_LOGIN);

            if (cleverapps.config.debugMode && connector.platform.oneOf(connector.WECHAT)) {
                alert("second login! old - " + oldId + ", new - " + newId);
            }

            if (!connector.info.isNative && !connector.platform.oneOf(connector.MSSTART, connector.WECHAT)
                && !(connector.platform.oneOf(connector.WORTAL) && (cleverapps.config.debugMode || connector.platform.debugMode))) {
                connector.dataLoader.cleanAll();
                cleverapps.dataLoader.cleanAll();
                connector.dataLoader.setEnabled(false);
                cleverapps.dataLoader.setEnabled(false);
                window.location.reload();
                return;
            }

            cleverapps.synchronizer.reset();
            cleverapps.friends.reset();
            cleverapps.invitalbleFriends.reset();
        }

        // onLogin
        cleverapps.dataLoader.processSaveQueue();

        cleverapps.synchronizer.syncWhenReady();

        cleverapps.whenAllInitialized(this.onLoginRefreshes.bind(this));
    },

    onLoginRefreshes: function () {
        if (!connector.platform.oneOf(connector.VK, connector.GDCOM, connector.COOLMATHGAMES, connector.PLINGA, connector.DISCORD)) {
            cleverapps.notification.create("SocialManager.Connected");
        }

        connector.player.whenReady(function () {
            var userName = connector.player.firstName || connector.player.name;
            if (userName && !cleverapps.flags.nologin) {
                var image;
                if (connector.player.avatar) {
                    image = new cleverapps.UI.Avatar(connector.player.avatar);
                    if (!image.width || !image.height) {
                        image = undefined;
                    }
                }
                cleverapps.notification.create("SocialManager.Welcome", {
                    toReplace: {
                        userName: userName
                    },
                    image: image
                });
            }
        });

        cleverapps.returnBonus.checkSocial();

        cleverapps.restoreProgress.update();

        cleverapps.eventLogger.onUpdateUserId();
    },

    onPlayerInfoChanged: function () {
        if (connector.player.name) {
            var name = connector.player.name.substr(0, 50);
            cleverapps.info.setKeyValue("name", name);
        }

        if (connector.player.avatar) {
            var avatar = connector.player.avatar;
            if (avatar instanceof VirtualResource) {
                avatar = "#" + avatar.bundle.name + "/" + avatar.key;
            }

            cleverapps.info.setKeyValue("avatar", avatar);
            cleverapps.UI.Avatar.preload(avatar);
        }

        if (cleverapps.miniGame) {
            cleverapps.miniGame.refreshItems();
        }
    },

    share: function (channel, shareId, callback) {
        var shareData = cleverapps.SocialManager.getShareData(shareId);
        shareData.channel = channel;

        cleverapps.focusManager.display({
            stack: true,
            focus: "ShareLoading",
            action: function (f) {
                var waitWindow = new WaitWindow();
                var startListener;

                var closeWaitWindow = function () {
                    if (waitWindow) {
                        waitWindow.close();
                        waitWindow = undefined;
                    }

                    if (startListener) {
                        startListener.clear();
                        startListener = undefined;
                    }

                    f();
                };

                startListener = connector.platform.on("share:start", function () {
                    closeWaitWindow();
                });

                var callbackOnce = cleverapps.once(function (success) {
                    closeWaitWindow();

                    callback(success);
                });

                new ActionPlayer([
                    function (f) {
                        if (channel === "shareNative") {
                            f();
                        } else {
                            cleverapps.SocialManager.enforceLogin(function (code) {
                                if (code === cleverapps.CODE_SUCCEED) {
                                    f();
                                } else {
                                    callbackOnce(false);
                                }
                            });
                        }
                    },
                    function () {
                        connector.platform.shareCallback(shareData, callbackOnce);
                    }
                ]).play();
            }
        });
    },

    getShareData: function (id) {
        var data = cc.loader.getRes(bundles.main.jsons.vk_json);
        var vkPictures = data[id] || data.default;

        var path = id + "_" + cleverapps.settings.language;
        if (!bundles.shares.urls[path + "_1"]) {
            path = id + "_en";
        }
        if (!bundles.shares.urls[path + "_1"]) {
            path = "default_en";
        }

        var pictures = [];
        for (var i = 1; i < 15; i++) {
            var picture = bundles.shares.urls[path + "_" + i];

            if (!picture) {
                break;
            }

            pictures.push(picture.getUrl());
        }

        var shareData = {
            title: Messages.get("Share." + id + ".Name"),
            text: Messages.get("Share." + id + ".Description"),
            image: cleverapps.Random.mathChoose(pictures)
        };

        if (connector.platform.oneOf(connector.FACEBOOK)) {
            shareData.text = Messages.get(cleverapps.config.title);
        }

        if (connector.platform.oneOf(connector.VK)) {
            shareData.image = cleverapps.Random.mathChoose(vkPictures);
        }

        if (connector.platform.oneOf(connector.PLINGA)) {
            shareData.linktext = Messages.get("PlayNow");
        }

        if (connector.platform.oneOf(connector.DRAUGIEM)) {
            shareData.subTitle = Messages.get("PlayNow");
        }

        return shareData;
    }
};
