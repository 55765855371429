/**
 * Created by r4zi4l on 28.07.2022
 */

var Snapshots = function () {
    var delta = cleverapps.dataLoader.load(DataLoader.TYPES.SNAPSHOT_TIME_DELTA);
    DateShift.setShift(delta);

    if (delta) {
        var userData = cleverapps.user.getInfo();
        userData.forcedVisited = userData.visited + delta;
        delete userData.visited;
        cleverapps.user.updateInfo(userData);
    }

    if (cleverapps.isLocalhost()) {
        this.dbStore = new IndexedDBStore("snapshots");
    }
};

Snapshots.prototype.reset = function () {
    DateShift.setShift(0);
    cleverapps.dataLoader.remove(DataLoader.TYPES.SNAPSHOT_TIME_DELTA);
};

Snapshots.prototype.importData = function (snapshot, callback) {
    // migration from local checkpoints
    if (snapshot.data) {
        Object.keys(snapshot.data).forEach(function (key) {
            var tokens = key.split("_");

            if (tokens[0] !== "units") {
                return;
            }

            var code = tokens[1];
            var slot = tokens[2] || "";

            if (!snapshot.data["units" + slot]) {
                snapshot.data["units" + slot] = {};
            }

            snapshot.data["units" + slot][code] = snapshot.data[key];
            delete snapshot.data[key];
        });

        snapshot = snapshot.data;
    }

    // migration from old snapshots
    if (snapshot.sync) {
        snapshot.sync.date = snapshot.date;
        snapshot.sync.actions = snapshot.actions;
        snapshot.sync.settings = snapshot.settings;
        snapshot = snapshot.sync;
    }

    if (snapshot.scene && snapshot.scene.episodeNo === "undersea2") {
        snapshot.scene.episodeNo = "undersea";
        snapshot.scene.levelNo = 1;
    }

    // migrate from levels to locations
    if (snapshot.scene && snapshot.scene.levelNo && cleverapps.config.type === "merge") {
        snapshot.scene.episodeNo += snapshot.scene.levelNo + 1;
        snapshot.scene.levelNo = 0;
    }

    if (snapshot.scene && (snapshot.scene.episodeNo === 0 || snapshot.scene.episodeNo === "0") && cleverapps.config.type === "merge" && cleverapps.config.name !== "garden") {
        snapshot.scene.episodeNo = "main";
    }

    // migrate to units library with slots
    if (snapshot.unitslibrary && !snapshot.unitslibrary1) {
        snapshot.unitslibrary1 = {};
        snapshot.unitslibrary2 = {};
        snapshot.unitslibrary3 = {};
        snapshot.unitslibrary4 = {};
    }

    if (!snapshot.abtest) {
        snapshot.abtest = {};
    }

    if (!snapshot.metha) {
        snapshot.metha = {};
    }

    if (!snapshot.playSession) {
        snapshot.playSession = {};
    }

    cleverapps.snapshots.reset();

    if (cleverapps.localPushes) {
        cleverapps.localPushes.reset();
    }

    if (cleverapps.unitSavers) {
        cleverapps.unitSavers.reset();
    }

    if (cleverapps.GameSaver) {
        cleverapps.GameSaver.reset();
    }

    if (typeof Merge3Adapter !== "undefined") {
        Merge3Adapter.Reset(Meta.SLOTS);
    }

    snapshot.achievements = snapshot.achievements || 0;
    snapshot.energyProducts = snapshot.energyProducts || {};
    snapshot.adsLimits = snapshot.adsLimits || {};

    if (snapshot.date) {
        var delta = snapshot.date - Date.now();
        DateShift.setShift(delta);
        cleverapps.dataLoader.save(DataLoader.TYPES.SNAPSHOT_TIME_DELTA, delta);
    }
    if (!snapshot.abtest) {
        cleverapps.dataLoader.remove(DataLoader.TYPES.ABTEST);
    }

    var type;
    var importers = {};

    for (type in SnapshotSyncers.importers) {
        importers[type] = SnapshotSyncers.importers[type];
    }

    for (type in CustomSyncers.importerData) {
        if (!importers[type]) {
            importers[type] = CustomSyncers.importerData[type];
        }
    }

    if (!cleverapps.config.importMode) {
        snapshot.users.id = undefined;
    } else if (snapshot.users.id) {
        connector.platform.setUserID(snapshot.users.id);

        if (connector.social.oneOf(connector.TEST) && snapshot.isLoggedIn) {
            connector.social.getPlugin().updateSession(snapshot.users.id);
        }
    }

    for (type in importers) {
        if (type in snapshot) {
            var importer = importers[type];
            var data = cleverapps.clone(snapshot[type], true);

            if (importer.useSlots) {
                if (snapshot.slot) {
                    importer(snapshot.slot, data);
                }
            } else {
                importer(data);
            }
        }
    }

    cleverapps.meta.refreshLocation();

    cleverapps.abExperiments.updateGroups();

    if (cleverapps.travelBook) {
        cleverapps.travelBook.init();
    }

    Messages.loadLanguage(cleverapps.settings.language, callback);
};

Snapshots.prototype.canCreate = function () {
    var environment = cleverapps.environment;
    if (environment.isEditorScene() || environment.isAdministratorScene()
        || environment.isWysiwygScene() || environment.isAtlasAnalyzerScene()) {
        cleverapps.notification.create("Can't take snapshot on current scene");
        return false;
    }

    if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.notification.create("Can't take snapshot if outcome is victory");
        return false;
    }

    return true;
};

Snapshots.prototype.createSnapshot = function (data, onSuccess) {
    if (!this.canCreate()) {
        return;
    }

    onSuccess = onSuccess || function (targetUrl) {
        cleverapps.copyToClipboard(targetUrl, function () {
            cleverapps.notification.create("snapshot copied to clipboard");
        });
        this.openInNewTab(targetUrl);
    }.bind(this);

    var create = function (data) {
        var id = Math.random().toString(36).slice(2);

        this.saveSnapshot(id, data, onSuccess);
    }.bind(this);

    if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.introPlaying && Game.currentGame.cachedIntro) {
        create(Game.currentGame.cachedIntro);
        return;
    }

    if (data) {
        create(data);
    } else {
        SnapshotSyncers.extractData(create);
    }
};

Snapshots.prototype.saveSnapshot = function (id, data, onSuccess) {
    if (cleverapps.isLocalhost()) {
        this.dbStore.set(id, data, function () {
            onSuccess("#snapshot=" + id);
        });
    } else {
        this.uploadSnapshot(id, data, onSuccess);
    }
};

Snapshots.prototype.uploadSnapshot = function (id, data, onSuccess) {
    var restUrl = cleverapps.RestClient.standsRest("/snapshots/create/" + id);
    var targetUrl = cleverapps.RestClient.standsWeb("/" + id);

    cleverapps.RestClient.post(restUrl, data, function () {
        onSuccess(targetUrl);
    }, function (error) {
        console.log("connection error", error);
        cleverapps.notification.create(error.errorMessage || error.statusText || error);
    }, {
        ignoreNoRest: true
    });
};

Snapshots.prototype.openInNewTab = function (url) {
    connector.platform.openUrl(url);
};

Snapshots.prototype.getUrlById = function (id) {
    return cleverapps.RestClient.standsRest("/snapshots/get/" + id + ".json");
};

Snapshots.prototype.getSnapshot = function (id, callback) {
    if (cleverapps.isLocalhost()) {
        this.dbStore.get(id, function (snapshot) {
            if (snapshot) {
                callback(snapshot);
            } else {
                this.getSnapshotFromUrl(this.getUrlById(id), function (snapshot) {
                    if (snapshot) {
                        this.saveSnapshot(id, snapshot, function () {});
                    }

                    callback(snapshot);
                }.bind(this));
            }
        }.bind(this));
    } else {
        this.getSnapshotFromUrl(this.getUrlById(id), callback);
    }
};

Snapshots.prototype.getSnapshotFromUrl = function (url, callback) {
    cc.loader.load(
        url,
        function (error, response) {
            if (error) {
                cleverapps.notification.create(response.errorMessage || response.statusText || response || "no snapshot found");
            }
            callback(response[0]);
        }
    );
};

Snapshots.prototype.exportToJson = function (snapshot) {
    var element = document.createElement("a");
    element.href = URL.createObjectURL(new Blob([JSON.stringify(snapshot, null, 4)], {
        type: "application/json"
    }));
    element.setAttribute("download", cleverapps.config.name + "_" + cleverapps.user.getHumanReadableNumber() + ".json");
    element.style.display = "none";

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

Snapshots.prototype.downloadToLocalhost = function (snapshotId) {
    this.openInNewTab("http://localhost/" + cleverapps.config.name + "/#snapshot=" + snapshotId);
};

Snapshots.prototype.uploadToStaging = function (snapshot) {
    this.uploadSnapshot(snapshot.snapshotId, snapshot, function (targetUrl) {
        this.openInNewTab(targetUrl);
    }.bind(this));
};

Snapshots.prototype.importFromUrl = function (callback) {
    var url = prompt("enter snapshot url");

    var id = url && url.replace(/.*snapshot=([A-Za-z0-9]+).*/, "$1");
    if (id === url) {
        id = url && url.replace(/.*labsystech.ru\/([A-Za-z0-9]+).*/, "$1");
    }
    if (!id) {
        return;
    }

    this.getSnapshot(id, function (snapshot) {
        callback(snapshot);
    });
};

Snapshots.prototype.record = function () {
    SnapshotSyncers.extractData(function (data) {
        cleverapps.capturedSnapshot = data;
        cleverapps.actionsRecording = new CapturedScenario();

        PointerHandlerExtensions.in();
    });
};

Snapshots.prototype.stopRecording = function () {
    cleverapps.actionsRecording.pushAction({
        type: "finish"
    });

    PointerHandlerExtensions.out();

    var data = cleverapps.capturedSnapshot;
    data.actions = cleverapps.actionsRecording.steps;

    delete cleverapps.capturedSnapshot;
    delete cleverapps.actionsRecording;
    this.createSnapshot(data);
};

Snapshots.prototype.playActions = function (actions) {
    cleverapps.snapshotBar.hideButtons();
    cleverapps.snapshotBar.showLabel("**playback");

    console.log("actions: ", actions);

    var savedHandleTouchesBegin = cc.inputManager.handleTouchesBegin;
    cc.inputManager.handleTouchesBegin = cleverapps.extendFunc(cc.inputManager.handleTouchesBegin, function (touches) {
        if (touches.length > 0 && touches[0]._fromGamePlayer) {
            return this._super(touches);
        }
        console.log("Ignore user click");
    });

    new GamePlayer({
        finger: {
            image: bundles.finger.frames.tutorial_hand_png
        }
    }).play({
        actions: actions,
        delay: 0,
        initialDelay: 0
    }, function () {
        cleverapps.snapshotBar.removeLabel();
        cleverapps.snapshotBar.showButtons();

        cc.inputManager.handleTouchesBegin = savedHandleTouchesBegin;
    });
};