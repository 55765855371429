/**
 * Created by Andrey Popov on 5/15/24.
 */

var NoAdsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.product = Product.CreateById("noAds");

        this._super({
            name: "NoAdsWindow",
            title: "NoAdsWindow.title",
            content: this.createContent(),
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            footNote: this.createFootnote()
        });

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        NoAdsWindow.updateNudgeTime();

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.NOADS_PRODUCT.WINDOW_OPEN);
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "worker");
    },

    createContent: function () {
        var content = this.content = new cleverapps.Spine(bundles.noads_window.jsons.noads_gift_json);
        content.setSkin(cleverapps.config.soft ? "ruby" : "coin");
        content.setAnimation(0, "animation", true);

        this.addButton();
        this.addNote();
        this.addBadge();

        return content;
    },

    addButton: function () {
        var styles = cleverapps.styles.NoAdsWindow.button;

        var buttonSlot = this.content.getSlot("slot_button");
        var button = new cleverapps.UI.Button({
            text: this.product.getCurrentPrice(),
            width: buttonSlot.width,
            height: buttonSlot.height,
            onClicked: function () {
                this.product.buy(function (success) {
                    this.close();

                    if (success) {
                        this.product.logBuyItem();
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.NOADS_PRODUCT.WINDOW_PURCHASE);
                    }
                }.bind(this));
            }.bind(this)
        });

        buttonSlot.addChild(button);
        button.setPositionRound(styles);
    },

    addNote: function () {
        var noteSlot = this.content.getSlot("slot_text");

        var text = Messages.get("NoAdsWindow.note." + (cleverapps.config.soft ? "hard" : "soft"), {
            amount: this.product.reward.hard
        });
        var note = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.NOTE_WINDOW_TEXT);
        noteSlot.addChild(note);
        note.fitTo(noteSlot.width, noteSlot.height);
        note.setPositionRound(cleverapps.styles.NoAdsWindow.note);
    },

    addBadge: function () {
        var styles = cleverapps.styles.NoAdsWindow.badge;

        var badgeSlot = this.content.getSlot("slot_badge");
        var badge = new cc.Sprite(bundles.noads_window.frames.noads_badge_png);
        badgeSlot.addChild(badge);
        badge.setPositionRound(styles);

        var label = cleverapps.UI.generateImageText("+150", cleverapps.styles.FONTS.NOADS_BADGE);
        badge.addChild(label);
        label.setPositionRound(styles.label);
        label.setRotation(30);
    },

    createFootnote: function () {
        var styles = cleverapps.styles.NoAdsWindow.footnote;
        var note = new cc.Node();

        var text = cleverapps.UI.generateOnlyText("NoAdsWindow.footnote", cleverapps.styles.FONTS.WHITE_TEXT);
        text.setDimensions(styles.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.height);

        note.addChild(text);
        cleverapps.UI.wrap(note);
        note.setAnchorPoint(0.5, 0.5);

        this.underline = cleverapps.UI.drawUnderline(text, styles.underline);
        note.addChild(this.underline);

        cleverapps.UI.onClick(note, this.close.bind(this));
        return note;
    },

    listBundles: function () {
        return ["noads_window"];
    }
});

NoAdsWindow.needToNudge = function () {
    var lastNudgeTime = cleverapps.dataLoader.localStorage.getItem(DataLoader.TYPES.NO_ADS_NUDGE_TIME);

    if (lastNudgeTime && parseInt(lastNudgeTime) + NoAds.NUDGE_TIMEOUT > Date.now()) {
        return false;
    }

    return connector.ads.isInterstitialSupported && cleverapps.noAds.canBuy();
};

NoAdsWindow.updateNudgeTime = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.NO_ADS_NUDGE_TIME, Date.now());
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    NOTE_WINDOW_TEXT: {
        name: "nostroke",
        size: 46,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    NOADS_BADGE: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.NoAdsWindow = {
    button: {
        x: { align: "center" },
        y: { align: "center" }
    },

    badge: {
        x: { align: "center" },
        y: { align: "center" },
        label: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: 3 }
        }
    },

    note: {
        x: { align: "center" },
        y: { align: "center" }
    },

    footnote: {
        height: 90,
        underline: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 5 },
            height: 3
        }
    }
};
