/**
 * Created by spepa on 22.05.2024
 */

var YoutubeLogic = function () {
    PlatformLogic.call(this);
};

YoutubeLogic.prototype = Object.create(PlatformLogic.prototype);
YoutubeLogic.prototype.constructor = YoutubeLogic;

YoutubeLogic.prototype.onStart = function () {
    cleverapps.flags.set("largeTextHotfix", true);
};