/**
 * Created by spepa on 22.06.2023
 */

var Map2dFences = function (map) {
    this.map = map;
    this.fences = {};

    for (var name in this.map.regions) {
        if (name.startsWith("fence_")) {
            BorderRenderer.place(
                this.map.regions[name].positions, 
                this.imagePlacement.bind(this),
                { regionName: name }
            );
        }
    }

    Map2dFences.AutoCreateRegions(map);
};

Map2dFences.prototype.imagePlacement = function (borders, cell, options) {
    var regionName = options.regionName;

    borders.push({ name: "floor" });
    borders.forEach(function (border) {
        var decoratorCode = this.getDecoratorCode(border.name, regionName);

        if (!Map2dDecoratorView.getJson({ code: decoratorCode })) {
            return;
        }

        var fence = new Map2dDecorator({
            code: decoratorCode,
            x: cell.x,
            y: cell.y,
            layer: border.name === "floor" ? Map2d.ABOVE_GROUND : Map2d.LAYER_UNITS,
            zOrder: ["left", "down"].includes(border.name) ? 10 : -10,
            dynamic: true
        });
        this.addFence(fence, regionName);

        Map2d.currentMap.decorators.addDecorator(cell.x, cell.y, fence);
    }.bind(this));
};

Map2dFences.prototype.getDecoratorCode = function (imageName, regionName) {
    var bundle = regionName;
    if (!bundles[regionName]) {
        bundle = "fence_common";
    }
    var url = bundle + "_" + imageName;
    return bundle + "#" + url;
};

Map2dFences.prototype.addFence = function (fence, regionName) {
    this.fences[regionName] = this.fences[regionName] || {};
    this.fences[regionName][fence.x] = this.fences[regionName][fence.x] || {};
    (this.fences[regionName][fence.x][fence.y] = this.fences[regionName][fence.x][fence.y] || []).push(fence);
};

Map2dFences.prototype.getFencesNames = function () {
    var fences = [];
    for (var region in this.map.regions) {
        if (region.startsWith("fence_")) {
            fences.push(region);
        }
    }
    return fences;
};

Map2dFences.AutoCreateRegions = function (map) {
    Object.keys(bundles).forEach(function (name) {
        if (name.startsWith("fence_") && !map.regions[name]) {
            if (bundles[name].injectTo && bundles[name].injectTo.includes(Map2d.getUnitsTexture(map.unitsSkin))) {
                map.regions[name] = { positions: [] };
            }
        }
    });
};